import {
    SmartStorage
} from 'smart-core-util'
export const Colors = {
    'eventcool': "#D7290F",
    'novartis': '#0099ff',
    'dscl': '#00b4ed',
    'pfizer': '#E60012',
    'ipsen': '#224a81',
    'roche': ' #2e82ef',
    'lilly': '#D7290F',
    'crossevent': SmartStorage.get('--themeColor')
}