<template>
    <div>
        <Header backType="white" :back="true" :backEvent="true" @backEvent="back">
            <span slot="headText">支付信息</span>
        </Header>
        <div class="shopMsg_code">
            <p class="shopImg_code">
                <img :src="shopInfo.shopImg" alt>
            </p>
            <p class="shopName_code">{{shopInfo.shopName}}</p>
        </div>
        <div class="codeBgB">
            <div class="codeBg">
                <div class="shopCodeContent">
                    <div class="shopCodeTxt">
                        <p>消费金额</p>
                        <p class="shopCodePrice">¥ {{payResultInfo.money}}</p>
                    </div>
                    <barcode
                        class="shopCode"
                        :value="code.barcodes"
                        :options="barcode_option"
                        tag="svg"
                    ></barcode>
                </div>
                <div class="codeTips">
                    请把下方的付款码给商家核验
                    <span class="codeQiuLeft"></span>
                    <span class="codeQiuRight"></span>
                </div>
                <div class="codeNumRow">
                    <p>小秘书付款码</p>
                    <p class="codeNum">{{payResultInfo.payCode}}</p>
                </div>
            </div>
        </div>

        <Btn btnClass="normalBtn uploadBtnO" v-if="isNeedUploadInvoice && !mealRules.newUI" @callBack="submitCode">
            <span slot="btnContent">上传发票</span>
        </Btn>

        <div class="xmsPay_submit_btn" v-if="isNeedUploadInvoice && mealRules.newUI" @click="submitCode">
            上传发票
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Colors } from '@/utils/colors.js'
export default {
    name: "PaymentMessage",
    data() {
        return {
            isNeedUploadInvoice: false,
            payResultInfo: {},
            shopInfo: {},
            payCheckCodeUrl: "",
            barcode_option: {},
            code: [],
            color: '#000'
        };
    },
    computed: {
        ...mapGetters(["xmsPayResult", "event","mealRules"])
    },
    created() {
        this.createCode();
    },
    mounted() {
        let xmsPayResult = this.xmsPayResult;
        this.isNeedUploadInvoice = xmsPayResult.isNeedUploadInvoice;
        this.payResultInfo = xmsPayResult.payResultInfo;
        this.shopInfo = xmsPayResult.shopInfo;
        this.payCheckCodeUrl = '/QrImg/GenerateNoPrefix?text='+xmsPayResult.payResultInfo.payCheckCodeUrl;                      
    },
    methods: {
        createCode() {
            this.color = Colors[this.event.tenant_code];
            this.barcode_option = {
                displayValue: false, //是否默认显示条形码数据
                background: "#fff", //条形码背景颜色
                valid: function(valid) {
                    console.log(valid);
                },
                width: "1", //单个条形码的宽度
                height: "70rem",
                fontSize: "1rem", //字体大小
                lineColor: this.color,
                margin: 0
            };
            this.code = { barcodes: "A01-01-01-01" };
        },
        submitCode() {
            this.$router.push("/uploadInvoice");
        },
        back() {
            const query = this.$route.query
            this.$router.push({ path: query.backUrl })
        }
    }
};
</script>

<style lang="scss">
@import "XMSPay";
    .xmsPay_submit_btn {
        position: fixed;
        bottom: 0;
        width: 95vw;
        height: 0.5rem;
        line-height: 0.5rem;
        border-radius: 0.04rem;
        margin: 0.15rem 2.5vw 0.25rem 2.5vw;
        box-shadow: 0px 4px 22px 0px rgba(0,0,0,0.2);
        background: var(--themeColor);
        color: #FFFFFF;
    }
</style>